@font-face {
  font-family: "MaisonNeue";
  src: url("./fonts/MaisonNeueWEB-Mono.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Harbour-Medium";
  src: url("./fonts/Harbour-Medium.woff2") format("woff2"),
    url("./fonts/Harbour-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  color: #000;
  background-color: #fff;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  overscroll-behavior: none;
}

* {
  outline: none;

  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
}
