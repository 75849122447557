h1,
h2 {
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 7.3rem;
  font-family: "Harbour-Medium";
}
h2 {
  font-size: 2.9rem;
  letter-spacing: -0.03rem;
  font-family: "MaisonNeue";
  color: #ba8f5c;
}

p,
a,
span {
  font-family: "MaisonNeue";
  font-size: 1.9rem;
  line-height: 2.8rem;

  color: #ba8f5c;

  margin: 0;
  padding: 0;

  text-decoration: none;

  @media (max-width: 1200px) {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
}

a:hover {
  color: #000;
}

.fx {
  display: inline-block;
  /* */
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1); /* older webkit */
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275); /* easeOutBack */

  -webkit-transition-timing-function: cubic-bezier(
    0.175,
    0.885,
    0.32,
    1
  ); /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-timing-function: cubic-bezier(
    0.175,
    0.885,
    0.32,
    1.275
  ); /* easeOutBack */
  /**/
}
